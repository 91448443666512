@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Lexend+Tera:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: #d2ab66;
}

input,
button {
  font: inherit;
}

.toaster-container {
  min-width: 320px;
  width: auto;
}

.toaster-container > div {
  padding: 0;
  border-radius: 8px;
}

.toaster-container > div > div {
  padding: 0;
  margin: 0;
}

.Toastify__toast-body > div {
  height: 100%;
}

.Toastify__toast-container {
  width: min(320px, 100%)!important
}

.form-row input,
.StripeElement {
  height: initial !important;
  padding: initial !important;
  border: initial !important;
  border-radius: initial !important;
  box-shadow: initial !important;
}
